/* eslint-disable react/no-array-index-key */
import CheckIcon from '@mui/icons-material/Check'
import {
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import refDataById from '../../services/localRefData/refDataById'

const dataFieldTypes = [
  'TextField',
  'DateField',
  'Select',
  'RadioGroup',
  'CheckBox',
  'CheckBoxGroup',
  'FieldArray',
  'FileInput',
]

const processFieldArray = async (fieldArrayData, fieldDefinition) => {
  const columnHeaders = fieldDefinition?.fields.map(field => field.label) || []

  const processedData = await Promise.all(
    fieldArrayData.map(async nestedRecord => {
      const rowData = await Promise.all(
        fieldDefinition?.fields.map(async field => {
          const value = nestedRecord[field.name]

          if (field.refDataKey) {
            const refValue = await refDataById(field.refDataKey, parseInt(value, 10))
            return refValue?.es_AR || ''
          }

          return value || ''
        }),
      )

      return rowData
    }),
  )
  return (
    <TableContainer component={Paper} sx={{ marginTop: '8px' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {columnHeaders.map(header => (
              <TableCell key={header}>
                <Typography fontWeight="bold" variant="caption">
                  {header}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {processedData.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <TableCell key={cellIndex} align={Number(cell) ? 'right' : 'left'}>
                  <Typography variant="body2">{cell}</Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const processFileInput = fileInputData => {
  return (
    <Stack>
      {fileInputData.map(fileUrl => {
        const fileName = fileUrl.split('/').pop()
        return (
          <Link key={fileUrl} href={fileUrl} target="_blank">
            {fileName}
          </Link>
        )
      })}
    </Stack>
  )
}

const formatDate = date => {
  return date ? date.split('-').reverse().join('/') : ''
}

const processRefDataField = async (value, fieldDefinition, refDataKeys) => {
  if (
    fieldDefinition &&
    fieldDefinition.refDataKey &&
    refDataKeys.includes(fieldDefinition.refDataKey)
  ) {
    if (Array.isArray(value)) {
      const values = await Promise.all(
        value.map(id => refDataById(fieldDefinition.refDataKey, parseInt(id, 10))),
      )
      return values.map(v => v?.es_AR || '').join(' - ')
    }
    const refValue = await refDataById(fieldDefinition.refDataKey, parseInt(value, 10))
    return refValue?.es_AR || ''
  }
  if (typeof value === 'boolean') {
    return value ? 'Sí' : 'No'
  }
  if (value === 'true' || value === 'false') {
    return value === 'true' ? 'Sí' : 'No'
  }
  return value
}

export const processRecordData = async ({
  record,
  formDefinition,
  refDataKeys,
  farmSubdivision,
}) => {
  const { data } = record
  const row = {
    id: record.id,
    paddocks:
      data.selectedPaddocks
        ?.map(id => {
          const feature = farmSubdivision?.features?.find(f => f.properties.id === id)
          return feature?.properties?.name || ''
        })
        .join(' - ') || 'General',
  }

  const componentProcessors = {
    FieldArray: (value, definition) => processFieldArray(value, definition),
    FileInput: value => processFileInput(value),
    DateField: value => formatDate(value),
    default: (value, definition) => processRefDataField(value, definition, refDataKeys),
  }

  const processField = async key => {
    const fieldDefinition = formDefinition.fields.find(f => f.name === key)

    const processor =
      componentProcessors[fieldDefinition?.component_type] || componentProcessors.default
    return processor(data[key], fieldDefinition)
  }

  const fieldPromises = Object.keys(data).map(async key => {
    const value = await processField(key)
    if (value !== null) {
      row[key] = value
    }
  })

  await Promise.all(fieldPromises)
  return row
}

export const createColumns = async (formDefinition, specificColumns) => {
  return formDefinition.fields
    .reduce(
      (cols, field) => {
        if (field.label && field.name && dataFieldTypes.includes(field.component_type)) {
          cols.push({
            name: field.name,
            label: field.label,
            options: {
              customBodyRender: value => {
                if (typeof value === 'boolean') return value ? <CheckIcon color="success" /> : null
                if (Array.isArray(value)) return value.join(' - ')
                return value
              },
            },
          })
        }
        return cols
      },
      [
        { name: 'id', label: 'id', options: { display: false } },
        { name: 'metricYear', label: 'Año' },
        { name: 'paddocks', label: 'Lote/s' },
        {
          name: 'notApplicable',
          label: 'No aplica',
          options: {
            customBodyRender: value => (value ? <CheckIcon color="success" /> : null),
          },
        },
      ],
    )
    .filter(col => specificColumns.includes(col.name))
}
