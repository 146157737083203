import React, { useEffect, useState, useCallback, useRef } from 'react'
import { MapContainer, GeoJSON, TileLayer, Popup, Marker } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'leaflet-gpx'
import { Grid, Stack, Typography } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import {
  confirmedSite,
  unconfirmedSite,
  tasksAssigned,
  gpsWaypoint,
} from '../../assets/leafletIcons'
import { ruutsApi } from '../../services/ruutsApi/index'
import { allHubsId } from '../../services/farmMapping/getFarms'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'leaflet.markercluster/dist/MarkerCluster.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import ActivityAssignmentForm from './ActivityAssignmentForm'
import ActivityList from './ActivityList'
import { getUncroppedSamplingAreasByFarm } from '../../services/farmMapping/samplingAreas/getSamplingAreas'

const EventViewerMap = ({
  currentFarm,
  monitoringSitesWithTrackPoints,
  waypoints,
  setWaypoints,
  gpx,
  setMonitoringSites,
  eventData,
  setEventData,
  assignedTo,
}) => {
  const { getAccessTokenSilently, user } = useAuth0()
  const [hubUsers, setHubUsers] = useState()
  const [map, setMap] = useState()
  const [samplingAreas, setSamplingAreas] = useState()
  const markerRefs = useRef({})
  const setRef = (key, element) => {
    markerRefs.current[key] = element
  }

  const getUsersFromMyHub = useCallback(async () => {
    const token = await getAccessTokenSilently()

    const q = {
      query: `user_metadata.hubId:${currentFarm.hubId} OR user_metadata.hubId:${allHubsId}`,
    }
    const users = await ruutsApi.getApiData(ruutsApi.endpoints.users, q, token)

    // sort users to show the current user first
    users.sort((a, b) => {
      if (a.email === user.email) return -1
      if (b.email === user.email) return 1
      return 0
    })
    setHubUsers(users)
  }, [currentFarm.hubId, getAccessTokenSilently, user.email])

  useEffect(() => {
    getUsersFromMyHub()
    return () => null
  }, [monitoringSitesWithTrackPoints, currentFarm, map, getUsersFromMyHub])

  const getMarkerIcon = useCallback(
    site => {
      const hastActivities = Object.keys(eventData)
        .map(key => eventData[key])
        .some(
          monitoringSite =>
            monitoringSite.id === site.id &&
            monitoringSite.monitoringActivities?.some(activity => activity.enabled),
        )
      if (hastActivities) {
        return tasksAssigned
      }
      if (site.locationConfirmed) {
        return confirmedSite
      }
      return unconfirmedSite
    },
    [eventData],
  )

  useEffect(() => {
    async function getSamplingAreas() {
      const token = await getAccessTokenSilently()
      const samplingAreasFromDb = await getUncroppedSamplingAreasByFarm({
        farmId: currentFarm.id,
        token,
      })
      setSamplingAreas(samplingAreasFromDb)
    }
    getSamplingAreas()
  }, [getAccessTokenSilently, currentFarm])

  useEffect(() => {
    if (map && monitoringSitesWithTrackPoints && gpx) {
      const reader = new FileReader()

      // Convert the file blob into text
      reader.onload = e => {
        const gpxText = e.target.result

        // Create the GPX layer with marker options
        const gpxLayer = new L.GPX(gpxText, {
          gpx_options: {
            joinTrackSegments: false,
            parseElements: ['track'],
          },
          markers: {
            startIcon: null,
            endIcon: null,
            wptIcons: {
              '': gpsWaypoint.svg,
            },
          },
          async: true,
          polyline_options: {
            color: '#2196f3',
            weight: 2,
          },
        })

        // Add the GPX layer to the map
        gpxLayer.addTo(map)
      }
      reader.readAsText(gpx)
    }
  }, [gpx, monitoringSitesWithTrackPoints, map])

  return (
    <Grid container height="73vh" spacing={2}>
      <Grid item xs={3}>
        <ActivityList eventData={eventData} markerRefs={markerRefs} setEventData={setEventData} />
      </Grid>
      <Grid item xs={9}>
        <MapContainer
          ref={setMap}
          center={[51.505, -0.09]}
          style={{ height: '73vh', width: '100%' }}
          whenReady={({ target }) => {
            target.fitBounds(L.geoJSON(currentFarm.toGeoJSON).getBounds())
          }}
          zoom={13}
        >
          <TileLayer
            attribution=""
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          />
          {currentFarm && (
            <GeoJSON
              data={currentFarm.toGeoJSON}
              style={{ fillColor: '#ff98200', color: '#ff9800', weight: 1 }}
            />
          )}
          {samplingAreas &&
            samplingAreas.length > 0 &&
            samplingAreas.map(sa => (
              <GeoJSON
                key={sa.id}
                data={sa.toGeoJSON}
                style={{ fillColor: sa.color || '#ff9800', color: '#ff9800', weight: 0.5 }}
              />
            ))}
          <MarkerClusterGroup>
            {waypoints &&
              waypoints.length > 0 &&
              waypoints.map(waypoint => (
                <Marker
                  key={waypoint.id}
                  icon={gpsWaypoint.leafletIcon}
                  position={[waypoint.lat, waypoint.lng]}
                >
                  <Popup>{waypoint.name}</Popup>
                </Marker>
              ))}
          </MarkerClusterGroup>

          {hubUsers &&
            hubUsers.length > 0 &&
            monitoringSitesWithTrackPoints &&
            monitoringSitesWithTrackPoints.length > 0 &&
            monitoringSitesWithTrackPoints.map((monitoringSite, index) => (
              <Marker
                key={monitoringSite.id + monitoringSite.name}
                ref={element => setRef(monitoringSite.id, element)}
                offset={[20, 20]}
                autoPan
                autoPanOnFocus
                eventHandlers={{
                  click: ({ target }) => {
                    map.panTo(target.getLatLng(), { animate: true })
                  },
                }}
                icon={getMarkerIcon(monitoringSite).leafletIcon}
                position={[monitoringSite.location[0], monitoringSite.location[1]]}
              >
                <Popup autoPan keepInView autoPanPadding={[-20, -20]} style={{ width: '200px' }}>
                  <ActivityAssignmentForm
                    assignedTo={assignedTo}
                    currentFarm={currentFarm}
                    eventData={eventData}
                    hubUsers={hubUsers.map(hubUser => hubUser.email)}
                    markerRef={markerRefs.current[monitoringSite.id]}
                    monitoringSite={monitoringSite}
                    monitoringSiteIndex={index}
                    setEventData={setEventData}
                    setMonitoringSites={setMonitoringSites}
                    setWaypoints={setWaypoints}
                    waypoints={waypoints}
                  />
                </Popup>
              </Marker>
            ))}
        </MapContainer>
        <Stack
          flex
          alignItems="center"
          direction="row"
          gap={2}
          justifyContent="center"
          mt={2}
          width="100%"
        >
          <Stack flex alignItems="center" direction="row" gap={0.5}>
            <img alt="confirmed site" src={confirmedSite.svg} width="22px" />
            <Typography variant="caption">Sitio confirmado</Typography>
          </Stack>
          <Stack flex alignItems="center" direction="row" gap={0.5}>
            <img alt="validation site" src={unconfirmedSite.svg} width="22px" />
            <Typography variant="caption">Sitio sin confirmar</Typography>
          </Stack>
          <Stack flex alignItems="center" direction="row" gap={0.5}>
            <img alt="pending site" src={gpsWaypoint.svg} width="22px" />
            <Typography variant="caption">GPS Waypoint</Typography>
          </Stack>
          <Stack flex alignItems="center" direction="row" gap={0.5}>
            <img alt="tasks assigned site" src={tasksAssigned.svg} width="22px" />
            <Typography variant="caption">Sitio con tareas asignadas</Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default EventViewerMap
