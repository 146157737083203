import findClosestPointOnPerimeter from './findClosestPointOnPerimeter.js'
import getDistanceUtm from './getDistanceUtm.js'
import distanceOnPerimeter from './getDistanceOnPerimeter.js'
import validateInputStringCoordinates from './validateInputStringCoordinates.js'

export const geoLocations = {
  findClosestPointOnPerimeter,
  getDistanceUtm,
  distanceOnPerimeter,
  validateInputStringCoordinates,
}
