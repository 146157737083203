import {
  Box,
  FormControl,
  FormHelperText,
  OutlinedInput,
  InputLabel,
  Typography,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import validateInputStringCoordinates from '../../services/geoLocations/validateInputStringCoordinates'

const CoordinatesInput = ({ readOnly, selectedConfirmationSite }) => {
  const { control, setValue, getValues } = useFormContext()

  const validateLatitude = lat => {
    if (Number.isNaN(lat)) {
      return false
    }
    if (lat < -90 || lat > 90) {
      return false
    }
    return true
  }

  const validateLongitude = lng => {
    if (!lng) {
      return false
    }
    if (Number.isNaN(lng)) {
      return false
    }
    if (lng < -180 || lng > 180) {
      return false
    }
    return true
  }

  const validateDecimals = value => {
    if (!value.toString().split('.')[1] || value.toString().split('.')[1]?.length < 9) {
      return false
    }
    return true
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Controller
        valueAsNumber
        control={control}
        defaultValue={
          selectedConfirmationSite?.id === 'manual' ? '' : selectedConfirmationSite?.lat || ''
        }
        name="lat"
        render={({ field, fieldState }) => (
          <FormControl
            fullWidth
            color={fieldState.error ? 'error' : 'primary'}
            error={!!fieldState.error}
            size="small"
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-coords">Latitud</InputLabel>
            <OutlinedInput
              {...field}
              error={!!fieldState.error}
              inputProps={{
                min: -90,
                max: 90,
                step: 0.000000001,
              }}
              label="Latitud"
              placeholder="Latitud"
              readOnly={readOnly}
              type="number"
              onChange={event => {
                if (event.target.value) {
                  field.onChange(event.target.valueAsNumber)
                  setValue(
                    'actualLocation',
                    [event.target.valueAsNumber, getValues('actualLocation')[1]],
                    { shouldValidate: true },
                  )
                } else {
                  field.onChange('')
                }
              }}
            />
            {fieldState.error && (
              <FormHelperText color={fieldState.error ? 'error' : 'inherit'}>
                {fieldState.error?.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
        rules={{
          required: 'Campo requerido',
          valueAsNumber: true,
          validate: {
            latitude: value => validateLatitude(value) || 'Latitud inválida',
            decimals: value => validateDecimals(value) || 'Al menos 9 decimales',
          },
        }}
      />
      <Controller
        control={control}
        defaultValue={
          selectedConfirmationSite?.id === 'manual' ? '' : selectedConfirmationSite?.lng || ''
        }
        name="lng"
        render={({ field, fieldState }) => (
          <FormControl fullWidth error={!!fieldState.error} size="small" variant="outlined">
            <InputLabel htmlFor="outlined-coords">Longitud</InputLabel>
            <OutlinedInput
              {...field}
              error={!!fieldState.error}
              inputProps={{
                min: -180,
                max: 180,
                step: 0.000000001,
              }}
              label="Longitud"
              placeholder="Longitud"
              readOnly={readOnly}
              type="number"
              onChange={event => {
                if (event.target.value) {
                  field.onChange(event.target.valueAsNumber)
                  setValue(
                    'actualLocation',
                    [getValues('actualLocation')[0], event.target.valueAsNumber],
                    { shouldValidate: true },
                  )
                } else {
                  field.onChange('')
                }
              }}
            />
            {fieldState.error && <FormHelperText>{fieldState.error?.message}</FormHelperText>}
          </FormControl>
        )}
        rules={{
          required: 'Campo requerido',
          valueAsNumber: true,
          validate: {
            longitude: value => validateLongitude(value) || 'Longitud inválida',
            decimals: value => validateDecimals(value) || 'Al menos 9 decimales',
          },
        }}
      />
      <Controller
        control={control}
        name="actualLocation"
        render={({ field, fieldState }) => (
          <Typography color={fieldState.error ? 'error' : 'inherit'} variant="bodySmall">
            {Array.isArray(field.value) && field.value.length > 0 ? field.value.join(', ') : ''}
          </Typography>
        )}
        rules={{
          required: 'Requerido',
          validate: value => validateInputStringCoordinates(value) || 'Coordenadas inválidas',
        }}
      />
    </Box>
  )
}

export default CoordinatesInput
