import { useAuth0 } from '@auth0/auth0-react'
import { Box, Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import AppContext from '../../../context/appContext'
import { useFormDefinitions } from '../../../hooks/data/useFormDefinitions'
import { generateReport } from '../../../services/reports/reportService'
import AttestationReportForm from '../Attestation/AttestationReportForm'
import { ReportsTable } from './ReportsTable'

export const ReportsHome = () => {
  const navigate = useNavigate()
  const { setLoadingModalConfig, currentFarm } = useContext(AppContext)
  const [selectedReportType, setSelectedReportType] = useState('')
  const [displayAddForm, setDisplayAddForm] = useState(false)
  const { formDefinitions, isLoading } = useFormDefinitions()
  const namespaces = formDefinitions?.map(({ label, namespace }) => ({
    label,
    namespace,
  }))

  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    setLoadingModalConfig({
      open: isLoading,
      dialogMessage: 'Cargando información...',
    })
  }, [isLoading, setLoadingModalConfig])

  const submitReport = async data => {
    const token = await getAccessTokenSilently()
    const generatedReport = await generateReport({
      reportData: {
        ...data,
        type: selectedReportType,
        farm: currentFarm,
      },
      token,
    })
    navigate(`/reports/${generatedReport.type}/${generatedReport.id}`)
  }

  const handleCancel = () => {
    setDisplayAddForm(false)
  }

  const REPORT_FORM_TYPES = {
    Attestation: (
      <AttestationReportForm
        namespaces={namespaces}
        onCancel={handleCancel}
        onSubmit={submitReport}
      />
    ),
  }

  if (isLoading || !currentFarm) return null

  return (
    <Box
      sx={{
        display: 'flex',
        padding: '16px',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Tipo de reporte</InputLabel>
        <Select
          id="report-type-select"
          label="Tipo de reporte"
          labelId="report-type-select-label"
          value={selectedReportType}
          onChange={event => setSelectedReportType(event.target.value)}
        >
          {Object.keys(REPORT_FORM_TYPES).map(reportType => (
            <MenuItem key={reportType} value={reportType}>
              {reportType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Divider />
      {displayAddForm ? (
        REPORT_FORM_TYPES[selectedReportType]
      ) : (
        <ReportsTable
          farmId={currentFarm?.id}
          reportType={selectedReportType}
          setDisplayAddForm={setDisplayAddForm}
        />
      )}
    </Box>
  )
}
