import { CircularProgress, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useFormDefinitions } from '../../hooks/data/useFormDefinitions'
import { useMetricEvents } from '../../hooks/data/useMetricEvents'
import lfStore from '../../lfstore/lfStore'
import { useCustomSnackbarError } from '../../utils/Snackbar/useCustomSnackbarError'
import { ExpandableDataTable } from '../ExpandableDataTable/ExpandableDataTable'
import { createColumns, processRecordData } from '../ExpandableDataTable/dataTableHelpers'

const ReportMetricsTable = ({ farmId, namespace, year, farmSubdivision, onMetricLoaded }) => {
  const [rowData, setRowData] = useState()
  const [columns, setColumns] = useState()
  const { metricEvents, isLoading } = useMetricEvents({ farmId, namespace, year })

  const { formDefinitions, isLoading: isFormDefinitionsLoading } = useFormDefinitions()
  const { handleError } = useCustomSnackbarError()

  const formDefinition = useMemo(
    () => formDefinitions?.find(fd => fd.namespace === namespace),
    [formDefinitions, namespace],
  )

  useEffect(() => {
    async function setUpData() {
      if (!formDefinition || !farmSubdivision) return

      try {
        if (metricEvents?.length) {
          const refDataKeys = await lfStore.keys()
          const newColumns = await createColumns(formDefinition, ['metricYear', 'paddocks'])
          setColumns(newColumns)
          const rows = await Promise.all(
            metricEvents.map(async record =>
              processRecordData({
                record,
                columnNames: newColumns.map(col => col.name),
                formDefinition,
                refDataKeys,
                farmSubdivision,
              }),
            ),
          )
          setRowData(rows)
        }
        onMetricLoaded(namespace)
      } catch (error) {
        handleError(error.message, error)
      }
    }
    setUpData()
  }, [formDefinition, metricEvents, handleError, farmSubdivision, onMetricLoaded, namespace])

  if (isLoading || isFormDefinitionsLoading) return <CircularProgress />

  if (!metricEvents?.length)
    return <Typography typography="h6">No hay métricas para este año</Typography>

  if (metricEvents.some(event => event.data.notApplicable))
    return <Typography typography="h6">{formDefinition.requiredMessage || 'No Aplica'}</Typography>

  return (
    columns &&
    rowData && (
      <ExpandableDataTable
        isAllExpanded
        columns={columns}
        customOptions={{
          expandableRowsOnClick: true,
          expandableRowsHeader: false,
          responsive: 'standard',
          elevation: 0,
        }}
        data={rowData}
        excludedDataKeys={['findings', 'isVerified', 'metricStatusId', 'notApplicable']}
        formDefinition={formDefinition}
      />
    )
  )
}

export default ReportMetricsTable
