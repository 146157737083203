import endpoints from './endpoints'
import postApiData from './postApiData'

export default async function compareGpsData({ farm, file, token, eventEndDate, eventStartDate }) {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('farmId', farm.id)
  formData.append('eventStartDate', eventStartDate)
  formData.append('eventEndDate', eventEndDate)
  const confirmationResults = await postApiData(endpoints.compareGpsData, formData, token)
  return confirmationResults.payload
}
