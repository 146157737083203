import postApiData from './postApiData'
import endpoints from './endpoints'

export async function addMonitoringEvent({
  token,
  farmId,
  isBackdatedEvent,
  eventData,
  eventStartDate,
  eventEndDate,
  gpsData,
  siteConfirmationRequired,
}) {
  if (gpsData) {
    const formData = new FormData()
    formData.append('file', gpsData)
    formData.append('farmId', farmId)
    formData.append('eventData', JSON.stringify(eventData))
    formData.append('isBackdatedEvent', isBackdatedEvent)
    formData.append('eventStartDate', eventStartDate)
    formData.append('eventEndDate', eventEndDate)
    formData.append('siteConfirmationRequired', siteConfirmationRequired)
    await postApiData(endpoints.v2.events, formData, token)
  } else {
    await postApiData(
      endpoints.v2.events,
      {
        farmId,
        eventData,
        eventStartDate,
        eventEndDate,
        isBackdatedEvent,
        siteConfirmationRequired,
      },
      token,
    )
  }
}
