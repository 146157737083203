import { useState, useEffect, useCallback } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Box, IconButton, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'

const paginationModel = { page: 0, pageSize: 5 }

const ActivitySummaryTable = ({ eventData, setEventData }) => {
  const [rows, setRows] = useState()
  const [columns, setColumns] = useState()

  const handleDelete = useCallback(
    id => {
      const row = rows.find(r => r.id === id)
      setRows(rows.filter(r => r.id !== id))
      setEventData(s => {
        const update = { ...s }
        delete update[row.id]
        return update
      })
    },
    [rows, setEventData],
  )

  const getColumns = useCallback(() => {
    const cols = [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
        flex: 1,
        renderCell: params => {
          return (
            <Typography
              component="div"
              style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
              variant="caption"
            >
              {params.value}
            </Typography>
          )
        },
      },
      {
        field: 'name',
        headerName: 'Nombre',
        flex: 1,
        renderCell: params => {
          return (
            <Typography
              component="div"
              style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
              variant="caption"
            >
              {params.value}
            </Typography>
          )
        },
      },
      {
        field: 'locationMoved',
        headerName: 'Relocalizado',
        flex: 1,
        valueGetter: field => {
          return field.value ? 'Si' : 'No'
        },
        renderCell: params => {
          return (
            <Typography
              component="div"
              style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
              variant="caption"
            >
              {params.value}
            </Typography>
          )
        },
      },
      {
        field: 'actualLocation',
        headerName: 'Coordenadas ejecutadas',
        flex: 1,
        valueGetter: field => {
          return field.value || '-'
        },
        renderCell: params => {
          return (
            <Typography
              component="div"
              style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
              variant="caption"
            >
              {params.value.join('\n')}
            </Typography>
          )
        },
      },
      {
        field: 'locationMovedReasonName',
        headerName: 'Motivo',
        flex: 1,
        valueGetter: field => {
          return field.value || '-'
        },
        renderCell: params => {
          return (
            <Typography
              component="div"
              style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
              variant="caption"
            >
              {params.value}
            </Typography>
          )
        },
      },
      {
        field: 'locationMovedComments',
        headerName: 'Comentarios',
        flex: 1,
        valueGetter: field => {
          return field.value || '-'
        },
        renderCell: params => {
          return (
            <Typography
              component="div"
              style={{ whiteSpace: 'pre-line', textAlign: 'center', wordWrap: 'pretty' }}
              variant="caption"
            >
              {params.value}
            </Typography>
          )
        },
      },
      {
        field: 'monitoringActivities',
        headerName: 'Actividades',
        flex: 1,
        valueGetter: field => {
          return field.value
            .filter(activity => activity.enabled)
            .map(activity => {
              return `${activity.monitoringWorkflowName} - ${activity.assignedTo}`
            })
            .join('\n')
        },
        renderCell: params => {
          return (
            <Typography
              component="div"
              style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
              variant="caption"
            >
              {params.value}
            </Typography>
          )
        },
      },
      {
        field: 'actions',
        headerName: '',
        width: 30,
        sortable: false,
        renderCell: params => {
          return (
            <Box sx={{ textAlign: 'center' }}>
              <IconButton color="error" size="small" onClick={() => handleDelete(params.row.id)}>
                <Delete fontSize="12" />
              </IconButton>
            </Box>
          )
        },
      },
    ]
    setColumns(cols)
    return cols
  }, [handleDelete])

  const getRows = useCallback(() => {
    setRows(Object.values(eventData))
  }, [eventData])

  useEffect(() => {
    getColumns()
    getRows()
  }, [getColumns, getRows])

  return (
    columns && (
      <Box
        flex
        alignItems="center"
        flexDirection="column"
        height="600px"
        justifyContent="space-between"
        width="100%"
      >
        <DataGrid
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          checkboxSelection={false}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[5, 10]}
          rowHeight={100}
          rows={rows}
          sx={{ border: 0 }}
        />
      </Box>
    )
  )
}

export default ActivitySummaryTable
