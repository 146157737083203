import { useAuth0 } from '@auth0/auth0-react'
import { useCallback } from 'react'
import listFilesService from '../services/objectStore/listFiles'
import uploadFiles from '../services/objectStore/uploadFiles'

export const useDOSpaces = folderPath => {
  if (!folderPath) {
    throw new Error('No se ha especificado la ruta del reporte')
  }

  const { getAccessTokenSilently } = useAuth0()

  const listFiles = useCallback(async () => {
    const token = await getAccessTokenSilently()
    const files = (await listFilesService(folderPath, token)) || []
    return files
  }, [folderPath, getAccessTokenSilently])

  const uploadPdf = async file => {
    try {
      const token = await getAccessTokenSilently()
      const fileLinks = await uploadFiles(folderPath, [file], token)
      return fileLinks
    } catch (error) {
      console.error('Error subiendo PDF:', error)
      throw error
    }
  }

  return {
    listFiles,
    uploadPdf,
  }
}
