import endpoints from '../ruutsApi/endpoints'
import getApiData from '../ruutsApi/getApiData'

const namespace = 'rpd'

export default async function listFiles(directoryName, token) {
  try {
    const directoryNameEncoded = encodeURIComponent(directoryName)
    return await getApiData(
      `${endpoints.objectStore}/${namespace}/${directoryNameEncoded}`,
      undefined,
      token,
    )
  } catch (e) {
    console.error(e)
    throw new Error('Unable to list files in S3')
  }
}
