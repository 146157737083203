import React, { useState, useEffect, useCallback } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Select,
  MenuItem,
  CircularProgress,
  Box,
  Typography,
  InputLabel,
  FormControl,
} from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { Controller, useFormContext } from 'react-hook-form'
import { localRefData } from '../../services/localRefData'

const LocationMovedModal = ({ open, handleClose }) => {
  const [locationMovedOptions, setLocationMovedOptions] = useState([])
  const [loadingOptions, setLoadingOptions] = useState(true)
  const [file, setFile] = useState(null)
  const [error, setError] = useState('')
  const { control, setValue } = useFormContext()

  useEffect(() => {
    async function getLocationMovedOptions() {
      try {
        setLoadingOptions(true)
        const options = await localRefData.getDataByKey('RefLocationMovedReason')
        setLocationMovedOptions(options)
      } catch (e) {
        console.error('Failed to load location moved options', e)
      } finally {
        setLoadingOptions(false)
      }
    }
    getLocationMovedOptions()
  }, [])

  // Handle form submission
  const handleSubmit = () => {
    handleClose()
  }

  // React Dropzone for file handling
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0])
      setError('') // Clear any previous errors
    }
  }, [])

  const handleCancel = () => {
    handleClose()
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    multiple: false,
  })

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle>Relocalización de sitio</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          {loadingOptions ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Controller
                control={control}
                name="locationMovedReasonId"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="site-moved-option">Motivo</InputLabel>
                    <Select
                      {...field}
                      fullWidth
                      label="Motivo"
                      labelId="site-moved-option"
                      onChange={event => {
                        setValue(
                          'locationMovedReasonName',
                          locationMovedOptions.find(option => option.id === event.target.value)
                            ?.es_AR,
                        )
                        field.onChange(event)
                      }}
                    >
                      <MenuItem disabled value="">
                        Seleccionar un motivo
                      </MenuItem>
                      {locationMovedOptions.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.es_AR}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="locationMovedReasonName"
                render={({ field }) => <input type="hidden" {...field} value={field.value} />}
              />
            </>
          )}
          <Controller
            control={control}
            name="locationMovedComments"
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                multiline
                label="Comentarios"
                placeholder="Agregar mas detalles..."
                rows={4}
                variant="outlined"
                onChange={field.onChange}
              />
            )}
          />
          <Box
            {...getRootProps()}
            sx={{
              border: '2px dashed #ccc',
              borderRadius: 2,
              p: 3,
              textAlign: 'center',
              cursor: 'pointer',
              backgroundColor: isDragActive ? '#f0f0f0' : '#fafafa',
              '&:hover': { backgroundColor: '#f5f5f5' },
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the files here...</Typography>
            ) : (
              <Typography>
                {file
                  ? `Archivo seleccionado: ${file.name}`
                  : 'Arrastrar o clickear para seleccionar un archivo'}
              </Typography>
            )}
          </Box>

          {file && (
            <Typography sx={{ mt: 1 }} variant="body2">
              Archivo seleccionado: {file.name}
            </Typography>
          )}

          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LocationMovedModal
