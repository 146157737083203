import { useCallback } from 'react'
import { useSnackbar } from 'notistack'
import { customSnackbarError } from './Snackbar'

export function useCustomSnackbarError(defaultErrorMessage = 'Ocurrió un error inesperado.') {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const handleError = useCallback(
    (error, customMessage, retryError = true) => {
      const message = `${customMessage || (error && error.message) || defaultErrorMessage} ${retryError ? ' Por favor, intenta nuevamente.' : ''} `
      customSnackbarError(message, error, enqueueSnackbar, closeSnackbar)
    },
    [defaultErrorMessage, enqueueSnackbar, closeSnackbar],
  )

  return { handleError }
}
