import { Box, Skeleton, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useFarmSubdivisionsList } from '../../../hooks/data/useFarmSubdivisionsList'
import { getRandomColor } from '../../../utils/mapDrawHandle'
import { Map } from '../Map/Map'
import ReportMetricsTable from '../ReportMetricsTable'

export const AttestationReportDetail = ({ year, includedMetrics, farm, onLoadComplete }) => {
  const { farmSubdivisions, isLoading } = useFarmSubdivisionsList(farm.id, year)
  const [loadedMetrics, setLoadedMetrics] = useState(new Set())

  useEffect(() => {
    if (loadedMetrics.size === includedMetrics.length || (!isLoading && !farmSubdivisions?.[0])) {
      onLoadComplete(year)
    }
  }, [
    includedMetrics.length,
    loadedMetrics.size,
    farmSubdivisions,
    isLoading,
    year,
    onLoadComplete,
  ])

  const handleMetricLoaded = useCallback(metric => {
    setLoadedMetrics(prev => {
      const updated = new Set(prev)
      updated.add(metric)
      return updated
    })
  }, [])

  if (isLoading) return <Skeleton />

  const perimeter = farm?.toGeoJSON
  const [farmSubdivision] = farmSubdivisions

  if (!farmSubdivision) return null

  const paddocksLayer = farmSubdivision.features.map(feature => {
    const { properties } = feature
    return {
      ...feature,
      properties: {
        ...properties,
        color: getRandomColor(),
      },
    }
  })
  return (
    <>
      <div className="html2pdf__page-break" />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '16px' }}>
        <Typography sx={{ fontWeight: 'bold' }} typography="h5">
          Loteo {year}
        </Typography>
        <Map isImage flexDirection="column" mapHeight={600} mapWidth={600} perimeter={perimeter}>
          <Map.Layer layer={paddocksLayer} />
          <Map.Legend layers={[perimeter, paddocksLayer]} />
        </Map>
      </Box>

      <div className="html2pdf__page-break" />

      {includedMetrics.map(({ namespace, label }) => (
        <Box key={namespace} sx={{ marginBottom: '16px' }}>
          <Typography sx={{ fontWeight: 'bold' }} typography="h5">
            Datos de {label} - {year}
          </Typography>
          <ReportMetricsTable
            key={namespace}
            farmId={farm.id}
            farmSubdivision={farmSubdivision}
            namespace={namespace}
            year={year}
            onMetricLoaded={handleMetricLoaded}
          />
        </Box>
      ))}
    </>
  )
}
