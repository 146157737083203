import { Add, Download } from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useDOSpaces } from '../../../hooks/useDOSpaces'
import { useCustomSnackbarError } from '../../../utils/Snackbar/useCustomSnackbarError'
import { SPACES_FOLDER } from '../constants'

export const ReportsTable = ({ setDisplayAddForm, reportType, farmId }) => {
  const [reports, setReports] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { handleError } = useCustomSnackbarError()

  const handleDownload = url => {
    window.open(url, '_blank noopener noreferrer')
  }

  const folderName = `farms/${farmId}/reports/${SPACES_FOLDER[reportType]}`

  const { listFiles } = useDOSpaces(folderName)

  useEffect(() => {
    const getReports = async () => {
      try {
        setIsLoading(true)
        const files = await listFiles()
        setReports(files)
      } catch (error) {
        handleError(error)
      } finally {
        setIsLoading(false)
      }
    }
    if (reportType) getReports()
  }, [handleError, listFiles, reportType])

  if (!reportType) return null

  return (
    <Stack alignItems="end" spacing={1}>
      <Box>
        <Button
          color="primary"
          startIcon={<Add />}
          style={{ marginBottom: '16px' }}
          variant="contained"
          onClick={() => setDisplayAddForm(true)}
        >
          Nuevo
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Fecha</b>
              </TableCell>
              <TableCell>
                <b>Nombre</b>
              </TableCell>
              <TableCell>
                <b>Acciones</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading ? (
              reports.length > 0 ? (
                reports.map(row => (
                  <TableRow key={row.key}>
                    <TableCell>{dayjs(row.lastModified).format('DD/MM/YYYY HH:mm')}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      <IconButton color="primary" onClick={() => handleDownload(row.downloadUrl)}>
                        <Download />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    No se encontraron reportes
                  </TableCell>
                </TableRow>
              )
            ) : (
              Array.from(new Array(3)).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton height={40} variant="rectangular" width={40} />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
