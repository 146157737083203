import { Autocomplete, TextField, Typography } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'

const SelectHubUser = ({
  hubUsers,
  readOnly,
  disabled,
  field,
  handleChange,
  fieldState,
  required,
  fullWidth,
  style,
  size,
}) => {
  const { user: currentUser } = useAuth0()

  return (
    hubUsers &&
    hubUsers.length > 0 && (
      <Autocomplete
        {...field}
        autoSelectsmall
        disableClearable
        disablePortal
        disabled={disabled}
        fullWidth={fullWidth}
        ListboxProps={{
          style: {
            maxHeight: '200px',
            overflow: 'auto',
          },
        }}
        multiple={false}
        options={hubUsers}
        readOnly={readOnly}
        renderInput={params => (
          <TextField
            {...params}
            error={fieldState ? !!fieldState?.error : false}
            label="Técnico"
            required={required}
            size={size || 'small'}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option}>
            {option === currentUser.email ? (
              <Typography>
                {option} <b>(Tú mismo)</b>
              </Typography>
            ) : (
              <Typography>{option}</Typography>
            )}
          </li>
        )}
        required={required}
        sx={style}
        onChange={(event, value) => (handleChange ? handleChange(value) : field?.onChange(value))}
      />
    )
  )
}

export default SelectHubUser
