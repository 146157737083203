import L from 'leaflet'
import gpsWaypointIcon from './gpsWaypoint.svg'
import monitoringSiteEnabled from './monitoringSiteEnabled.svg'
import navigation from './navigation.svg'
import validationSiteIcon from './validationSite.svg'
import monitoringSiteWithTasks from './monitoringSiteWithTasks.svg'
import monitoringSiteWarning from './monitoringSiteWarning.svg'

export const currentLocation = {
  leafletIcon: L.icon({
    iconUrl: navigation,
    iconRetinaUrl: navigation,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [32, 32],
    className: 'leaflet-venue-icon',
  }),
  svg: navigation,
}

export const unconfirmedSite = {
  leafletIcon: L.icon({
    iconUrl: monitoringSiteWarning,
    iconRetinaUrl: monitoringSiteWarning,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [32, 32],
    className: 'leaflet-venue-icon',
  }),
  svg: monitoringSiteWarning,
}

export const confirmedSite = {
  leafletIcon: L.icon({
    iconUrl: monitoringSiteEnabled,
    iconRetinaUrl: monitoringSiteEnabled,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [32, 32],
    className: 'leaflet-venue-icon',
  }),
  svg: monitoringSiteEnabled,
}

export const validationSite = {
  leafletIcon: L.icon({
    iconUrl: validationSiteIcon,
    iconRetinaUrl: validationSiteIcon,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [32, 32],
    className: 'leaflet-venue-icon',
  }),
  svg: validationSiteIcon,
}

export const tasksAssigned = {
  leafletIcon: L.icon({
    iconUrl: monitoringSiteWithTasks,
    iconRetinaUrl: monitoringSiteWithTasks,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 30],
    className: 'leaflet-venue-icon',
  }),
  svg: monitoringSiteWithTasks,
}

export const gpsWaypoint = {
  leafletIcon: L.icon({
    iconUrl: gpsWaypointIcon,
    iconRetinaUrl: gpsWaypointIcon,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [32, 32],
    className: 'leaflet-venue-icon',
  }),
  svg: gpsWaypointIcon,
}
