/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable max-len */
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import AppContext from '../../../context/appContext'
import 'dayjs/locale/es-us'
import 'dayjs/locale/en'
import 'dayjs/locale/pt-br'

const getLang = () => {
  const rpdLocale = localStorage?.getItem('lang') || 'es_AR'
  const dayJSLocaleMap = {
    es_AR: 'es-us',
    en_US: 'en',
    pt_BR: 'pt-br',
    default: 'es-us',
  }
  return dayJSLocaleMap[rpdLocale] || dayJSLocaleMap.default
}

const AttestationReportForm = ({ namespaces = [], onSubmit, onCancel }) => {
  const { currentFarm } = useContext(AppContext)

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: dayjs().startOf('day'),
      attestationContent: '',
      city: currentFarm?.city || '',
      province: currentFarm?.province || '',
      country: currentFarm?.country || '',
      responsible: '',
      startYear: null,
      periodStart: null,
      periodEnd: null,
      includedMetrics: namespaces.reduce(
        (acc, { namespace }) => ({
          ...acc,
          [namespace]: false,
        }),
        {},
      ),
    },
  })
  const [allSelected, setAllSelected] = useState(false)

  const includedMetrics = watch('includedMetrics')
  const periodStart = watch('periodStart')

  useEffect(() => {
    if (includedMetrics) {
      const allChecked = namespaces.every(({ namespace }) => includedMetrics[namespace])
      setAllSelected(allChecked)
    }
  }, [includedMetrics, namespaces])

  const handleSelectAll = () => {
    const newValue = !allSelected
    setAllSelected(newValue)
    namespaces.forEach(({ namespace }) => {
      setValue(`includedMetrics.${namespace}`, newValue)
    })
  }

  const handleFormSubmit = data => {
    if (onSubmit) {
      const selectedMetrics = Object.entries(data.includedMetrics)
        .filter(([_, value]) => value)
        .map(([key]) => key)
      const includedNamespaces = namespaces.filter(namespace =>
        selectedMetrics.includes(namespace.namespace),
      )
      onSubmit({ ...data, includedMetrics: includedNamespaces })
    }
  }

  return (
    <LocalizationProvider adapterLocale={getLang()} dateAdapter={AdapterDayjs}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="date"
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Fecha"
                  slots={{
                    textField: params => (
                      <TextField
                        {...params}
                        error={!!errors.date}
                        helperText={errors.date?.message}
                      />
                    ),
                  }}
                />
              )}
              rules={{ required: 'Campo requerido' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="attestationContent"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  error={!!errors.attestationContent}
                  helperText={errors.attestationContent?.message}
                  label="Contenido"
                  rows={8}
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Controller
              control={control}
              name="city"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!errors.city}
                  helperText={errors.city?.message}
                  label="Ciudad"
                />
              )}
              rules={{ required: 'Campo requerido' }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Controller
              control={control}
              name="province"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!errors.province}
                  helperText={errors.province?.message}
                  label="Provincia"
                />
              )}
              rules={{ required: 'Campo requerido' }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Controller
              control={control}
              name="country"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!errors.country}
                  helperText={errors.country?.message}
                  label="País"
                />
              )}
              rules={{ required: 'Campo requerido' }}
            />
          </Grid>
          <Grid item sm={3} xs={6}>
            <Controller
              control={control}
              name="periodStart"
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Inicio del Periodo"
                  slots={{
                    textField: params => (
                      <TextField
                        {...params}
                        error={!!errors.periodStart}
                        helperText={errors.periodStart?.message}
                      />
                    ),
                  }}
                  views={['year']}
                />
              )}
              rules={{ required: 'Campo requerido' }}
            />
          </Grid>
          <Grid item sm={3} xs={6}>
            <Controller
              control={control}
              name="periodEnd"
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Fin del Periodo"
                  slots={{
                    textField: params => (
                      <TextField
                        {...params}
                        error={!!errors.periodEnd}
                        helperText={errors.periodEnd?.message}
                      />
                    ),
                  }}
                  views={['year']}
                />
              )}
              rules={{
                required: 'Campo requerido',
                validate: {
                  isValid: value => dayjs(value).isValid() || 'Fecha inválida',
                  isAfterStart: value =>
                    !periodStart ||
                    !value ||
                    dayjs(value).isSame(dayjs(periodStart)) ||
                    dayjs(value).isAfter(dayjs(periodStart)) ||
                    'La fecha de fin debe ser igual o posterior a la fecha de inicio',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="responsible"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!errors.responsible}
                  helperText={errors.responsible?.message}
                  label="Responsable"
                />
              )}
              rules={{ required: 'Campo requerido' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Incluir Métricas</Typography>
            <Button onClick={handleSelectAll}>
              {allSelected ? 'Quitar Todo' : 'Seleccionar Todo'}
            </Button>
            <FormGroup>
              {namespaces.map(({ label, namespace }) => (
                <Controller
                  key={namespace}
                  control={control}
                  name={`includedMetrics.${namespace}`}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value || false} />}
                      label={label}
                    />
                  )}
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item sx={{ display: 'flex', gap: '8px' }} xs={12}>
            <Button color="primary" type="submit" variant="contained">
              Generar
            </Button>
            <Button color="error" variant="contained" onClick={() => onCancel()}>
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </LocalizationProvider>
  )
}

export default AttestationReportForm
