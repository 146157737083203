import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material'
import html2pdf from 'html2pdf.js'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import AppContext from '../../context/appContext'
import { useReport } from '../../hooks/data/useReport'
import { useDOSpaces } from '../../hooks/useDOSpaces'
import { useCustomSnackbarError } from '../../utils/Snackbar/useCustomSnackbarError'
import { NavHeader } from '../NavHeader/NavHeader'
import { AttestationReport } from './Attestation/AttestationReport'
import { REPORT_BACKGROUND_COLOR, SPACES_FOLDER } from './constants'
import { sanitizeString } from '../../utils/sanitizeStrings'

export const Report = () => {
  const { setHideNavbar } = useContext(AppContext)
  const { reportType, reportId } = useParams()
  const navigate = useNavigate()
  const { report, isLoading } = useReport({ reportId })
  const { handleError } = useCustomSnackbarError()
  const contentRef = useRef(null)
  const [isUploading, setIsUploading] = useState(false)
  const [isReportLoading, setIsReportLoading] = useState(false)

  const folderName = `farms/${report?.farm.id}/reports/${SPACES_FOLDER[reportType]}`
  const { uploadPdf } = useDOSpaces(folderName)

  const renderReport = useMemo(
    () => ({
      Attestation: <AttestationReport report={report} setIsReportLoading={setIsReportLoading} />,
    }),
    [report],
  )

  useEffect(() => {
    if (!isLoading && !report) {
      navigate('/reports')
    }
  }, [report, isLoading, navigate])

  useEffect(() => {
    setHideNavbar(true)
    return () => {
      setHideNavbar(false)
    }
  }, [setHideNavbar])

  const handleNavBack = () => {
    navigate('/reports')
  }

  const generatePdf = async () => {
    try {
      setIsUploading(true)
      const element = contentRef.current
      const filename = `${reportType}_${sanitizeString(report.farm.name)}_${report.date.format('DD-MM-YYYY')}.pdf`
      const options = {
        filename,
        ima3ge: { type: 'jpeg', quality: 1 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      }

      const style = document.createElement('style')
      document.head.appendChild(style)
      style.sheet?.insertRule('body > div:last-child img { display: inline-block; }')
      element.style.backgroundColor = REPORT_BACKGROUND_COLOR[reportType] || 'white'
      element.style.minHeight = '100%'

      const pdf = await html2pdf().set(options).from(element).outputPdf('blob')

      const pdfFile = new File([pdf], filename, {
        type: 'application/pdf',
      })
      await uploadPdf(pdfFile)

      html2pdf()
        .set(options)
        .from(element)
        .save()
        .then(() => {
          style.remove()
          navigate('/reports')
        })
    } catch (error) {
      handleError(error)
    } finally {
      setIsUploading(false)
    }
  }

  return (
    <>
      <NavHeader>
        <NavHeader.BackButton handleNavBack={handleNavBack}>
          <ArrowBackIosIcon />
        </NavHeader.BackButton>
        <NavHeader.Title>
          <Typography variant="bodyBold">{reportType}</Typography>
        </NavHeader.Title>
        <NavHeader.FarmDetails />
      </NavHeader>
      {isLoading ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        >
          <CircularProgress />
        </Box>
      ) : report ? (
        <>
          <Box
            sx={{
              display: 'flex',
              width: '210mm',
              margin: '24px auto',
              p: '16px',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <Button disabled={isUploading || isReportLoading} onClick={generatePdf}>
              Generar PDF
            </Button>
            {(isUploading || isReportLoading) && <CircularProgress size={24} />}
          </Box>
          <Paper
            sx={{
              width: '210mm',
              margin: '24px auto',
              backgroundColor: 'white',
              boxShadow: '0 0 0.5cm rgba(0,0,0,0.5)',
            }}
          >
            <div ref={contentRef}>{renderReport[reportType]}</div>
          </Paper>
        </>
      ) : null}
    </>
  )
}
